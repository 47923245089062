import React, { useState } from 'react';
import './App.css';
import { toKana } from 'wanakana';
import image from "./img/image.png"; 

let randomValue = function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min +1)) + min;
};

let mincar = 0;
let maxcar = 4;
let j = randomValue(mincar, maxcar); // index du caractère
let essais = 0; // nb de caractères devinés
let score = 0; // nb de caractères trouvés
let answer = ""; // caratère qu'il fallait trouver

const initialDate = new Date();
let initialTime = initialDate.getTime();

function App() {

  const essai = ['a', 'i', 'u', 'e', 'o',        //0, 4
  'ka', 'ki', 'ku', 'ke', 'ko',    //5, 9
  'sa', 'shi', 'su', 'se', 'so',   //10, 14
  'ta', 'chi', 'tsu', 'te', 'to',  //15, 19
  'na', 'ni', 'nu', 'ne', 'no',    //20, 24
  'ha', 'hi', 'fu', 'he', 'ho',    //25, 29
  'ma', 'mi', 'mu', 'me', 'mo',    //30, 34
  'ya', 'yu', 'yo',                //35, 37
  'ra', 'ri', 'ru', 're', 'ro',    //38, 42
  'wa', 'wo',                      //43, 44
  'n',                             //45
  'ga', 'gi', 'gu', 'ge', 'go',    //46, 50
  'za', 'ji', 'zu', 'ze', 'zo',    //51, 55
  'da', 'de', 'do',                //56, 58
  'ba', 'bi', 'bu', 'be', 'bo',    //59, 63
  'pa', 'pi', 'pu', 'pe', 'po',    //64, 68
  'kya', 'kyu', 'kyo',             //69, 71
  'gya', 'gyu', 'gyo',             //72, 74
  'sha', 'shu', 'sho',             //75, 77
  'ja', 'ju', 'jo',                //78, 80
  'cha', 'chu', 'cho',             //81, 83
  'nya', 'nyu', 'nyo',             //84, 86
  'hya', 'hyu', 'hyo',             //87, 89
  'bya', 'byu', 'byo',             //90, 92
  'pya', 'pyu', 'pyo',             //93, 95
  'mya', 'myu', 'myo',             //96, 98
  'rya', 'ryu', 'ryo'];             //99, 101

  const [car, setCar] = useState("");
  
  const getJ = () => j;
  const updateJ = () => {
    const theValue = getJ();
    console.log('j: %d',theValue);
    const jold = theValue;
    const jnew=randomValue(mincar, maxcar);
    if (jold === jnew) {updateJ()} else {j = jnew}
    const theUpdatedValue = getJ();
    console.log('updated j: %d',theUpdatedValue);
  }

  const updateInitialTime = () => {
    initialTime = date.getTime();
  }

  const getScore = () => score;
  const updateScore = () => {
    const theValue = getScore();
    console.log('score: %d',theValue);
    score++;
    const theUpdatedValue = getScore();
    console.log('updated score: %d',theUpdatedValue);
  }

  const getEssais = () => essais;
  const updateEssais = () => {
    const theValue = getEssais();
    console.log('essais: %d',theValue);
    essais++;
    const theUpdatedValue = getEssais();
    console.log('updated essais: %d',theUpdatedValue);
  }

  const getAnswer = () => answer;
  const updateAnswer = () => {
    const theValue = getAnswer();
    console.log('answer: %s',theValue);
    answer=essai[getJ()];
    const theUpdatedValue = getAnswer();
    console.log('updated answer: %s',theUpdatedValue);
  };

  const resetAnswer = () => {
    const theValue = getAnswer();
    console.log('answer: %s',theValue);
    answer="";
    const theUpdatedValue = getAnswer();
    console.log('reseted answer: %s',theUpdatedValue);
  };

  const showAnswer = () => {
    <h1>
      { console.log('NOK %s',car) }
      {/* essai[getJ()] */}
    </h1>
  };

  const handleSubmitJap = (event)=>{
    event.preventDefault();
    <div>
      { car === essai[getJ()] ? 
        updateJ() &
        updateScore() &
        setCar("") &
        resetAnswer()
        : updateAnswer() & showAnswer() & setCar("") }
      { updateEssais() }
    </div>
    event.target.reset();
  };

  const [favorite, setFavorite] = React.useState('Hiragana    ');

  const handleCatChange = () => {
    setFavorite('Hiragana    ');
    updateInitialTime();
  };

  const handleDogChange = () => {
    setFavorite('Katakana');
    updateInitialTime();
  };

  const RadioButton = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="radio" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };

const checkMin = () => {
  if (mincar<0) {mincar=0};
  if (mincar>100) {mincar=100};
  if (mincar>=maxcar) {mincar=maxcar-1};
  updateInitialTime();
}

const checkMax = () => {
  if (maxcar<1) {maxcar=1};
  if (maxcar>101) {maxcar=101};
  if (maxcar<=mincar) {maxcar=mincar+1};
  updateInitialTime();
}

const date = new Date();
const showTime = date.getTime();

  return (
    <div className="App">
      <header className="App-header" style={{ 
      backgroundImage: `url(${image})`,
      backgroundPosition: 'top',
      //backgroundSize: 'retain',
      backgroundRepeat: 'no-repeat',
      //height: '50%',
      height: '100vh'
    }}>
        <div>
          <RadioButton
            label="Katakana"
            value={favorite === 'Katakana'}
            onChange={handleDogChange}
          />
          <RadioButton
            label="Hiragana    "
            value={favorite === 'Hiragana    '}
            onChange={handleCatChange}
          />
          <div>
            <input type="number" style={{width: "50px", marginTop: 10}}
            placeholder={mincar} name="minbox" onChange={e=>{
              mincar=e.target.value;
              checkMin();
              e.target.value=mincar;
              console.log(mincar);
            }}
            />
            <input type="number" style={{width: "50px"}}
            placeholder={maxcar} name="maxbox" onChange={e=>{
              maxcar=e.target.value;
              checkMax();
              console.log(maxcar);
              e.target.value=maxcar;
            }}
            />
          </div>
          <h5 style={{ marginBottom: 0}}>{favorite === 'Hiragana    ' ? toKana('konnichiwa') : toKana('KONNICHIWA')}</h5> 
          <div className="rate-container">
            <h1 style={{ color: 'black', fontSize: '80px', marginBottom: 0, marginTop: 0}}>
              {favorite === 'Hiragana    ' ? toKana(essai[getJ()]) : toKana(essai[getJ()].toUpperCase())}
            </h1>
            <form onSubmit={handleSubmitJap}>
              <input
                    type="text"
                    placeholder="Write here"
                    onChange={e=>{
                      setCar(e.target.value.toLowerCase());
                    }}
                  />
              <button type="submit">submit</button>
              <h5>Réponse : {getAnswer()}</h5>
            </form>
            <h5>Score : {getScore()} / {getEssais()} en {Math.round((showTime - initialTime)/1000)} secondes</h5>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
